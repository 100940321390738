import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';
import { colors } from 'styles/theme';
import { leadingZero } from 'utils/leadingZero';
import { Grid, Box, Text } from 'components';

const Container = styled(Box)`
  width: 100%;
  position: relative;
  background: #fafafa;
`;

const Slide = styled(Box)`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-out;

  &:not(:first-of-type) {
    border-top: 1px solid ${colors.gray};
  }
`;

Slide.defaultProps = {
  bg: 'white'
};

const PageCounter = styled(Box)`
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: right;
`;

const TextContainer = Text.withComponent('div');

const Intro = ({ slides }) => {
  const refs = useRef(slides.map(React.createRef));
  const [currentSlide, setCurrentSlide] = useState(-1);
  const [currentFixed, setCurrentFixed] = useState(-1);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  const onScroll = () => {
    let max = -1;
    let maxFixed = -1;
    const vh = window.innerHeight;

    refs.current.forEach((ref, i) => {
      const y = ref.current.getBoundingClientRect().top;
      max = y <= vh * 0.5 ? i : max;
      maxFixed = y <= 0 ? i : max;
    });

    setCurrentSlide(max);
    setCurrentFixed(maxFixed);
  };

  // Render
  return (
    <Container>
      {slides.map((slide, i) => {
        return (
          <Slide
            key={i}
            ref={refs.current[i]}
            bg={i <= currentFixed ? '#fafafa' : 'white'}
          >
            <Grid>
              <Box gridColumn="4 / span 6">
                <TextContainer textAlign="center">
                  {RichText.render(slide.primary.paragraph)}
                </TextContainer>
              </Box>
            </Grid>
          </Slide>
        );
      })}

      <PageCounter p={2}>
        <Text>
          {leadingZero(Math.max(currentSlide + 1, 1))} of{' '}
          {leadingZero(slides.length)}
        </Text>
      </PageCounter>
    </Container>
  );
};

export default Intro;
