import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';

import { RichText } from 'prismic-reactjs';

import { Layout, SEO, Grid, Box, Text } from 'components';
import Intro from 'components/join/Intro';
import Positions from 'components/join/Positions';

import { linkResolver } from 'utils/linkResolver';

import theme from 'styles/theme';

const GeneralContainer = styled(Grid)`
  border-top: 1px solid ${theme.colors.gray};
`;

const GeneralLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.blue};

  &:hover {
    text-decoration: underline;
  }
`;

const Join = ({ data }) => {
  const page = data.prismic.allJoin_pages.edges.slice(0, 1).pop().node;
  const offices = data.prismic.allOffices.edges;
  const positions = data.prismic.allJob_items.edges;

  const metaData = {
    title: page.meta_title ? RichText.asText(page.meta_title) : null,
    description: page.meta_description
      ? RichText.asText(page.meta_description)
      : null
  };

  if (page.meta_image) {
    metaData.image = page.meta_image.url;
    metaData.meta = [
      {
        property: `og:image:width`,
        content: page.meta_image.dimensions.width
      },
      {
        property: `og:image:height`,
        content: page.meta_image.dimensions.height
      }
    ];
  }

  return (
    <Layout>
      <SEO {...metaData} />
      <Intro slides={page.body} />
      <Positions offices={offices} positions={positions} />

      <GeneralContainer bg="white">
        <Box gridColumn="span 6" textAlign="center" py={7}>
          <Text>Who we are</Text>
          <Text>
            <GeneralLink to="/about">Learn about us →</GeneralLink>
          </Text>
        </Box>
        <Box gridColumn="span 6" textAlign="center" py={7}>
          <Text>General inquiry</Text>
          <Text>
            <GeneralLink
              to={linkResolver({ uid: 'general-inquiry', type: 'job_item' })}
            >
              @General
            </GeneralLink>
          </Text>
        </Box>
      </GeneralContainer>
    </Layout>
  );
};

export default Join;

export const query = graphql`
  {
    prismic {
      allJoin_pages {
        edges {
          node {
            meta_title
            meta_description
            meta_image
            body {
              ... on PRISMIC_Join_pageBodySlide_card {
                primary {
                  paragraph
                }
              }
            }
          }
        }
      }
      allOffices(sortBy: order_ASC) {
        edges {
          node {
            _meta {
              uid
            }
            address
            city
            email
            tel
          }
        }
      }
      allJob_items(first:100) {
        edges {
          node {
            _meta {
              uid
              type
            }
            title
            office {
              ... on PRISMIC_Office {
                _meta {
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
`;
