import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, breakpoints } from 'styles/theme';
import { linkResolver } from 'utils/linkResolver';
import { RichText } from 'prismic-reactjs';
import { Box, Text } from 'components';

const officeColors = {
  'los-angeles': colors.red,
  'new-york': colors.blue,
  stockholm: colors.lime,
  london: colors.pink,
  berlin: colors.brown,
  barcelona: colors.cyan
};

const OfficeContainer = styled(Box)`
  border-top: 1px solid ${colors.gray};
  position: sticky;
  top: 64px;
  transition: background-color 0.2s ease-out;

  @media screen and (min-width: ${breakpoints.sm}) {
    top: 84px;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    top: 100px;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const PositionsHeader = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  border-top: 1px solid ${colors.gray};

  @media screen and (min-width: ${breakpoints.sm}) {
    height: 84px;
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    height: 100px;
  }
`;

const Positions = ({ offices, positions }) => {
  const headerRef = useRef();
  const refs = useRef(offices.map(React.createRef));
  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  const onScroll = () => {
    let max = -1;

    refs.current.forEach((ref, i) => {
      max =
        ref.current.getBoundingClientRect().top <=
        headerRef.current.offsetHeight
          ? i
          : max;
    });

    setCurrentIndex(max);
  };

  return (
    <Box textAlign="center">
      <PositionsHeader bg="white" ref={headerRef}>
        <Text textAlign="center" uppercase="true">
          Open positions
        </Text>
      </PositionsHeader>

      {offices.map((office, i) => {
        const officePositions = positions.filter(position => {
          if (position.node.office) {
            return position.node.office._meta.uid === office.node._meta.uid;
          }

          return false;
        });

        return (
          <OfficeContainer
            py={[5, null, 7]}
            px={2}
            bg={i <= currentIndex ? '#fafafa' : 'white'}
            ref={refs.current[i]}
            key={i}
          >
            <Text
              uppercase="true"
              mb={1}
              color={officeColors[office.node._meta.uid]}
            >
              {RichText.asText(office.node.city)}
            </Text>

            {officePositions.length ? (
              officePositions.map((position, j) => {
                return (
                  <Text key={j}>
                    <Link to={linkResolver(position.node._meta)}>
                      {RichText.asText(position.node.title)}
                    </Link>
                  </Text>
                );
              })
            ) : (
              <Text color="blackDimmed">
                Currently there aren’t any open positions
              </Text>
            )}
          </OfficeContainer>
        );
      })}
    </Box>
  );
};

export default Positions;
